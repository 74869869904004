.container{
    width: 5rem;
    margin: 2rem 5rem;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    p{
        font-size: 1.5rem;
        font-weight: 500;
        color: #fff;
        font-family: 'BatmanForeverOutline', sans-serif;
        font-family: 'BatmanForeverAlternate', sans-serif;
        margin-top: 1rem;
    }
}
/*Including dafont */
@font-face {
    font-family: SevenSegment;
    src: url('./fonts/ss.ttf');
}

.register-form-input{
    border-style: solid;
    border-radius: 10px;
    width: 400px;
    /* width: 21vw; */
    height: 60px;
    /* height: 3vw; */
    font-size: 20px;
    margin-bottom: 15px;
    margin-left: 12.5px;
}

.contact-us-form{
    display: flex;
    flex-direction: column;
    /* border-style: solid; */
}

.register-form{
    height: 600px;
    /* height: 35vw; */
    border-color: none;
    border-width: 0px;
    width: 450px;
    /* width: 25vw; */
    /* margin-top: -630px; */
    background-image: linear-gradient(to bottom,rgba(0, 0, 0, 0.123),rgba(0, 0, 0, 0.199));
    clip-path: polygon(20% 0%, 90% 0, 100% 11%, 100% 80%, 100% 100%, 10% 100%, 0 90%, 0 0);

}

.register-button-submit{

    --background-color: linear-gradient(90deg, #39008C 0%, #00FFFF 100%);
    /* --border-color:linear-gradient(to bottom right ,red,blue); */
    --border-width:.5em;
    --edge-size:.5em;
    font-weight:800;
    letter-spacing: 1px;
    font-size: 20px;
    margin-top: 13px;
    /* margin: 2vw; */
    color: white;
    display:inline-grid;
    padding: 0.5em 1.5em;
    border:0;
    background:var(--background-color);
    margin-left: 13px;
    width: 150px;
    /* margin-left: 0.8vw; */

}


.register-button-submit:hover{
    transition: all 4s ease;
    cursor: pointer;
    box-shadow: 0px 0px 3px aqua;
    --background-color: linear-gradient(90deg, #00FFFF 0%,  #39008C 100%);
}

.register-form-input:focus{
outline: none;
box-shadow: 0px 0px 2px 2px blue;
}

.label-choose-file{
/* border-style: solid; */
padding: 10px;
}


.query-box{
font-size: 21px;
border-style: solid;
font-family: sans-serif;
padding: 10px;
resize:none;
margin-left: 12.5px;
width: 400px;
height: 280px;
}

input[type="text"],
textarea {
    width:400px;
    padding: 3px;
    border-style: solid;
    border-radius: 10px;

    }


@media screen and (min-width:1500px) and (max-width:1850px){
    .register-form-input{
        width: 390px;
        height:50px;
        font-size: 15px;
        margin-bottom: 10px;
        margin-left: -5px;
    }
    .register-form{
        width: 400px;
        height: 470px;
        
    }
    .register-button-submit{
        font-size: 15px;
        margin-left: 10px;
    }
    .choose-file{
        height: 25px;
        width: 240px;
        font-size: 15px;
    }
    input[type="text"],
    textarea {
    width:265px;
    padding: 3px;
    border-style: solid;
    border-radius: 10px;

    }
    .query-box{
        font-size: 16px;
        width: 390px;
        height: 200px;
        margin-left: -5px;
    }
}




@media screen and (max-width:1500px){
    .register-form-input{
        width: 390px;
        height:50px;
        font-size: 15px;
        margin-bottom: 10px;
        margin-left: -5px;
    }
    .register-form{
        width: 400px;
        height: 490px;
        
    }
    .register-button-submit{
        font-size: 15px;
        margin-left: 10px;
        margin-top: 18px;
    }
    .choose-file{
        height: 25px;
        width: 240px;
        font-size: 15px;
    }
    input[type="text"],
    textarea {
    width:265px;
    padding: 3px;
    border-style: solid;
    border-radius: 10px;

    }
    .query-box{
        font-size: 16px;
        width: 390px;
        height: 200px;
        margin-left: -5px;
    }

}


@media screen and (max-width:1200px) {
    .register-form{
     justify-content: center;
     text-align: center;
     margin-left: -50px;
     width: 500px;
     height: 520px;
    }

    .register-button-submit{
        margin-top: 25px;
    }
 }
 

 @media screen and (max-width:650px) {
    .register-form{
     justify-content: center;
     text-align: center;
     /* margin-left: -50px; */
     width: 300px;
     height: 420px;
    }

    .register-form-input{
        width: 240px;
        height:30px;
        font-size: 10px;
        margin-bottom: 10px;
        margin-left: -5px;
    }

    .query-box{
        font-size: 10px;
        width: 240px;
        height: 200px;
        margin-left: -5px;
    }
    
    .register-button-submit{
        font-size: 10px;
        margin-left: 10px;
        margin-top: 11px;
    }

    .contact-us-form{
        margin-left: 10px;
    }
 }
 
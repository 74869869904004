.formContainer{
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    height: 70vh;
    width: 100%;
}

.container{
    margin: 1rem;
    width: 50vw !important;
}
@import url("http://fonts.cdnfonts.com/css/batman-forever");

html {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #1e1e1e;
  overflow-x: hidden !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* .App {
  scrollbar-color: rgb(48, 192, 227) black;
} */


/*Custom Scrollbar */
::-webkit-scrollbar{
  width: 10px;
  scroll-behavior: smooth;
}

::-webkit-scrollbar-thumb{
  background: linear-gradient(rgb(41, 41, 167),rgb(48, 192, 227));
}
.container{
    width: 100vw;
    height: auto;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
}

.eventHeading{
    font-family: 'BatmanForeverOutline', sans-serif;
    font-family: 'BatmanForeverAlternate', sans-serif;
    font-size: 2rem;
    text-align: center;
    user-select: none;
    padding: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 1rem;
    border-radius: 10px;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    z-index: 2;
}

.eventBox{
    width: 40rem;
    height: auto;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    background-color: rgba(255, 255, 255, 0.257);
    margin-bottom: 1rem;
    padding: 0.5rem 2rem 0.5rem 2rem;
    border-radius: 25px;
}

.groundmesh{
    display: fixed;
    position: absolute;
    width: 100%;
    height: 50%;
    background-color: transparent;
    bottom: -1rem;
    background-image: url("../../assets/ground.svg");
    background-position: center bottom;
    background-repeat: no-repeat;
    background-size:cover;
    opacity: 0.7;
}

.active{
    background-color: black; 
    padding: 0.5rem;
    margin: 0.5rem;
    font-size: 3rem;
    border-radius: 10px;
    color: #fff;
    z-index: 10000;
    position: relative;
    cursor: pointer;
}

.inactive{
    background-color: rgba(0, 0, 0, 0.121);
    padding: 0.5rem;
    margin: 0.5rem;
    border-radius: 10px;
    color: #fff;
    z-index: 10;
}

.swiperContainer{
    width: auto;
    margin-left: 2rem;
    margin-right: 2rem;
    z-index: 1;
}

@media screen and (max-width: 576px){
    .groundmesh{
        height: 30%;
    }
    .eventHeading{
         font-size: 1.5rem;
    }
    .eventBox{
        width: 100%;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
    .active{
        font-size: 2.1rem;
    }
    .inactive{
        font-size: 2rem;
    }
}

@media screen and (max-width: 360px){
    .eventHeading{
         font-size: 1rem;
    }
}
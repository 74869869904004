.split-image-container{
    display:flex;
    justify-content: center;
}

.split-image-container .split-image{
    width:80%;
    height: 533px;
    /* background: url('../../assets/astronaut.jpeg'); */
    background: url('./images/zaeden-internet.webp');
    animation: changeImage 20s linear infinite;
    /* border-radius: 20px; */
    /* border-style: solid;
    border-color: rgba(0, 45, 128, 0.438); */
    background-size: 800px;
}

.split-image{
    transition: all 0.3s ease;

}

.split-image-container .split-image:nth-child(1){
    width: 22%;
    /* border-style: solid; */
    background-position: -40px;
    /* border-radius: 10px; */
    margin:10px;
}

.split-image-container .split-image:nth-child(2){
    width: 22%;
    /* border-style: solid; */
    background-position: -220px;
    background-size: 800px;
    transform: scale(1.1);
    /* border-radius: 10px; */
    margin:10px;
}


.split-image-container .split-image:nth-child(3){
    width: 22%;
    /* border-style: solid; */
    /* background-position: -640px; */
    background-position: -400px;
    /* border-radius: 10px; */
    margin:10px;

}

.split-image-container:hover > .split-image{
    /* margin:15px; */
    margin:0px;
    box-shadow: 0px 0px 5px grey;
}


@media screen and (max-width:1350px) {
    

    .split-image-container .split-image{
        width:60%;
        height: 533px;
        margin-left: -30px;
        margin-right: -30px;
        
    }

    .split-image-container .split-image:nth-child(1){
        width: 25%;
        scale: 0.8;
        /* border-style: solid; */
        background-position: 0px;
        /* border-radius: 10px; */
        margin:-10px;
    }
    
    .split-image-container .split-image:nth-child(2){
        width: 25%;
        /* border-style: solid; */
        scale: 0.9;
        background-position: -280px;
        transform: scale(1.1);
        /* border-radius: 10px; */
        z-index: 1;
        margin:10px;
    }
    
    
    .split-image-container .split-image:nth-child(3){
        width: 25%;
        scale: 0.8;
        /* border-style: solid; */
        /* background-position: -640px; */
        background-position: -500px;
        /* border-radius: 10px; */
        margin:-10px;
    
    }   

    .split-image-container:hover > .split-image{
        /* margin:-10px; */
        margin:-20px;
        box-shadow: 0px 0px 5px grey;
    }
    
}


@media screen and (max-width:800px) {
   
    .split-image-container .split-image{
        width:60%;
        height: 700px;
        margin-left: -30px;
        margin-right: -30px;
        
    }

    .split-image-container .split-image:nth-child(1){
        width: 20%;
        scale: 0.7;
        /* height:450px; */
        /* border-style: solid; */
        background-position: 0px;
        /* border-radius: 10px; */
        background-size: 280px;
        height:180px;
        margin:20px;
        margin-top:20px;


    }
    
    .split-image-container .split-image:nth-child(2){
        width: 20%;
        height:200px;
        /* border-style: solid; */
        scale: 0.8;
        /* height:450px; */
        background-position: -90px;
        background-size: 800px;
        /* border-radius: 10px; */
        z-index: 1;
        margin:10px;

        background-size: 300px;
    }
    
    
    .split-image-container .split-image:nth-child(3){
        width: 20%;
        height:180px;
        scale: 0.7;
        /* height:450px; */
        /* border-style: solid; */
        /* background-position: -640px; */
        background-position: -190px;
        /* border-radius: 10px; */
        background-size: 300px;
        margin:20px;
        margin-top:20px;


    
    }   

    .split-image-container:hover > .split-image{
        /* margin:-17px; */
        margin:3px;
        box-shadow: 0px 0px 5px grey;
    }
    
}
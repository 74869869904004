.container{
    margin-top: 2rem;
    padding-bottom: 4rem;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    background: linear-gradient(90.65deg, #161616 0.57%, #111111 114.37%);
    width: 100%;
    backdrop-filter: blur(15px);
    z-index: 20;

    .left{
        display: flex;
        flex-direction: column;
        min-width: 45%;
        padding: 0.75rem;
        justify-content: space-between;
        align-items: center;

        img{
            max-width: 100%;
            margin-top: 2rem;
            margin-bottom: 1rem;
        }

        p{
            text-align: left;
            margin: 0 1rem;
            font-weight: 700;
        }
        
        .logos{
            width: 100%;
            // margin: 1rem 0;
            display: flex;
            flex-direction: row;
            // justify-content: flex-start;
            justify-content: center;
            align-items: center;
            flex-wrap: wrap;

            img{
                padding: 0 1rem;

                @media screen and (max-width: 576px){
                    padding: 0 0.5rem;
                    width: 15%;
                }   
            }
        }
    }

    .right{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        min-width: 40%;
        padding: 0.75rem;

        div>p{
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;
            flex-basis: 2;
        }

        h4{
            text-align: left;
        }
        button{
            border-radius: 50%;
            cursor: pointer;
            border: none;
            background: linear-gradient(179.69deg, #39008C 0.27%, #00FFFF 128.05%);;
        }
    }
}

.toTop{
    position: absolute;
    bottom: 2rem;
    right: 4rem;
    @media screen and (max-width:560px){
        display: none;
    }
}




@media screen and (max-width: 768px){
    .container{
        padding-bottom: 0;
    }
}

@media screen and (max-width:400px){

    .right,.left{
        font-size: 0.8rem;
        padding: 0.20rem;
    }

    .footerInfo{
        margin: 0 !important;
    }

    .left{
        .logos{
            font-size: 1rem;
            img{
                margin: 0 !important;
                margin-top: 1.25rem !important;
                padding-left: 0.25rem !important;
                padding-right: 0.25rem !important;
                padding-top: 0 !important;
                padding-bottom: 0 !important;
                width: 20% !important;
            }
        }
    }

    .contactIcons{
        width: 1rem;
    }
}